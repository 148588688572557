<template>
  <div class="row full-width">
    <q-card flat class="row full-width justify-between bg-transparent">
      <q-card-section class="col-12 col-md-6 q-pa-none">
        <div class="q-pa-xs full-height">
          <article class="full-height"
                   :style="[isMobile ? 'height: 250px !important' : 'height: 508px !important']" style="">
            <a :href="`/blog/${posts[0].id}`" style="border: 3px solid white">
              <q-img class="border-radius"
                     :src="posts[0].image && `${baseUrl}/cdn/download/${posts[0].image.fileName}`"
                     width="100%" height="100%">
                <div class="absolute-bottom">

                  <!-- title -->
                  <div class="row full-width q-mb-xs items-center">
                    <div class="line-clamp-1 text-h6 text-bold text-white">
                      {{ posts[0].title }}
                    </div>
                  </div>
                </div>
              </q-img>
            </a>
          </article>
        </div>
      </q-card-section>
      <q-card-section v-if="!isMobile" class="col-md-6 col-0 row q-pa-none items-start">
        <div class="col-12 q-pa-xs self-stretch">
          <article style="height: 250px">
            <a :href="`/blog/${posts[1].id}`" style="border: 3px solid white">
              <q-img class="border-radius"
                     :src="posts[1].image && `${baseUrl}/cdn/download/${posts[1].image.fileName}`"
                     width="100%" height="100%">
                <div class="absolute-bottom">

                  <!-- title -->
                  <div class="row full-width q-mb-xs items-center">
                    <div class="line-clamp-1 text-h6 text-bold text-white">
                      {{ posts[1].title }}
                    </div>
                  </div>
                </div>
              </q-img>
            </a>
          </article>
        </div>
        <div class="col self-stretch q-pa-xs">
          <article style="height: 250px">
            <a :href="`/blog/${posts[2].id}`" style="border: 3px solid white">
              <q-img class="border-radius"
                     :src="posts[2].image && `${baseUrl}/cdn/download/${posts[2].image.fileName}`"
                     width="100%" height="100%" style="max-height: 290px">
                <div class="absolute-bottom">

                  <!-- title -->
                  <div class="row full-width q-mb-xs items-center">
                    <div class="line-clamp-1 text-h6 text-bold text-white">
                      {{ posts[2].title }}
                    </div>
                  </div>
                </div>
              </q-img>
            </a>
          </article>
        </div>
        <div class="col self-stretch q-pa-xs">
          <article style="height: 250px">
            <a :href="`/blog/${posts[3].id}`" style="border: 3px solid white">
              <q-img class="border-radius"
                     :src="posts[3].image && `${baseUrl}/cdn/download/${posts[3].image.fileName}`"
                     width="100%" height="100%" style="max-height: 290px">
                <div class="absolute-bottom">

                  <!-- title -->
                  <div class="row full-width q-mb-xs items-center">
                    <div class="line-clamp-1 text-h6 text-bold text-white">
                      {{ posts[3].title }}
                    </div>
                  </div>
                </div>
              </q-img>
            </a>
          </article>
        </div>
      </q-card-section>
    </q-card>
  </div>

</template>

<script>
export default {
  name: "BlogTile",
  props: {
    posts: [],
  },
  data: () => ({
    baseUrl: process.env.API_URL
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
