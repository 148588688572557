<template>
  <router-link class="full-width" style="text-decoration: none" tag="div" :to="`/blog/${post.id}`">
    <div class="row full-width post overflow-hidden">

      <!-- image -->
      <div class="col-4">
        <q-img :src="post.image ? `${baseUrl}/cdn/download/${post.image.fileName}` : 'images/noPic.png'"
               width="100%" height="100%" :ratio="4/3" fit="cover">
          <template v-slot:loading>
            <q-spinner-tail color="black" size="xs"/>
          </template>
        </q-img>
      </div>

      <!-- body -->
      <div class="col-8">
        <div class="column full-width q-pa-md q-gutter-y-md">

          <!-- title -->
          <div class="row full-width items-center">
            <div class="line-clamp-1 text-subtitle2 text-weight-bolder text-black">
              {{ post.title }}
            </div>
          </div>

          <!-- view count, publish date -->
          <div class="row full-width justify-between items-center">

            <!-- view count -->
            <div class="text-caption text-grey-8">
              {{ post.viewCount + ' بازدید' }}
            </div>

            <!-- publish date -->
            <div class="text-caption text-grey-7">
              {{ post.publishDate && post.publishDate.toJalaliFullDate(false) }}
            </div>

          </div>

        </div>
      </div>

    </div>
  </router-link>
</template>

<script>
export default {
  name: "BlogPostSmall",
  props: {
    post: Object
  },
  data: () => ({
    baseUrl: process.env.API_URL
  })
}
</script>

<style lang="scss" scoped>
.post {
  background-color: white;
  transition: 0.4s;
}

.post:hover {
  background-color: $grey-3;
  transition: 0.4s;
}
</style>
