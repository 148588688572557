<template>
  <div class="full-width border-radius border-solid text-grey-4 bg-white q-pa-md">
    <div class="column full-width q-gutter-y-md">

      <!-- person title, date, rating -->
      <div class="row full-width justify-between items-center">

        <!-- person title, date -->
        <div class="row q-gutter-x-sm items-center">

          <!-- person title -->
          <div :class="['text-weight-regular text-black', isMobile ? 'text-caption' : 'text-subtitle2']">
            {{ comment.personTitle }}
          </div>

          <div class="text-primary">
            &bull;
          </div>

          <!-- date -->
          <div class="text-caption text-grey-8">
            {{ comment.date.getElapsedTime() }}
          </div>

        </div>

        <!-- rating -->
        <q-rating :model-value="comment.rating" color="yellow-8" size="1rem" icon="la la-star"
                  icon-half="la la-star-half" dir="ltr" readonly/>

      </div>

      <!-- text -->
      <div class="full-width">
        <div class="line-height text-caption text-weight-regular text-justify text-black">
          {{ comment.text }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CommentItem",
  props: {
    comment: Object
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    }
  }
}
</script>

<style scoped>
.line-height {
  line-height: 24px;
}
</style>
