<template>
  <router-link :class="[isMobile ? size === 6 ? 'hidden' : 'col-6 q-pl-sm' : `col-${size} q-pl-sm`]"
               style="text-decoration: none" tag="div"
               :to="link">
    <q-img class="border-radius" :src="image && `${baseUrl}/cdn/download/${image.fileName}`"
           width="100%" height="100%"/>
  </router-link>
</template>

<script>
export default {
  name: "SPromotion",
  props: {
    size: Number,
    link: String,
    image: String,
    title: String,
    description: String
  },
  data: () => ({
    baseUrl: process.env.API_URL
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    }
  }
}
</script>
