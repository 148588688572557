<template>
  <div class="column border-radius border-solid bg-white q-pa-md text-grey-4">

    <!-- title -->
    <div class="text-subtitle1 text-bold text-grey-9 q-px-sm">
      {{ title }}
    </div>

    <!-- separator -->
    <q-separator class="q-mx-xs q-my-md"/>

    <!-- posts -->
    <div class="row full-width">
      <div class="column full-width">
        <div class="column full-width q-gutter-y-md">

          <router-link v-for="post of posts" class="full-width" style="text-decoration: none" tag="div" :to="`/blog/${post.id}`">
            <div class="row full-width border-radius border-solid overflow-hidden text-grey-4">

              <!-- image -->
              <div class="col-12 col-md-5 border-right">
                <q-img :src="post.image ? `${baseUrl}/cdn/download/${post.image.fileName}` : 'images/noPic.png'"
                       height="100%" :ratio="4/3" fit="cover">
                  <template v-slot:loading>
                    <q-spinner-tail color="black" size="xs"/>
                  </template>
                </q-img>
              </div>

              <!-- body -->
              <div class="col-7 bg-white">
                <div class="column full-width q-pa-md q-gutter-y-sm">

                  <!-- title -->
                  <div class="row full-width items-center">
                    <div class="line-clamp-2 text-grey-9 text-subtitle1 text-bold">
                      {{ post.title }}
                    </div>
                  </div>

                  <!-- view count, publish date -->
                  <div class="row full-width justify-between items-center">

                    <!-- view count -->
                    <div v-if="showViewCount" class="text-caption text-grey-8">
                      {{ post.viewCount + ' بازدید' }}
                    </div>

                    <!-- like count -->
                    <div v-if="showLikeCount" class="text-caption text-grey-8">
                      {{ post.likeCount + ' لایک' }}
                    </div>

                    <!-- comment count -->
                    <div v-if="showCommentCount" class="text-caption text-grey-8">
                      {{ post.commentCount + ' نظر' }}
                    </div>

                    <!-- publish date -->
                    <div v-if="showPublishDate" class="text-caption text-grey-8">
                      {{ post.publishDate && post.publishDate.getElapsedTime() }}
                    </div>

                  </div>

                </div>
              </div>

            </div>
          </router-link>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "BlogSidebar",
  props: {
    title: String,
    posts: [],
    showPublishDate: {
      type: Boolean,
      default: 1
    },
    showViewCount: {
      type: Boolean,
      default: 1
    },
    showLikeCount: {
      type: Boolean,
      default: 0
    },
    showCommentCount: {
      type: Boolean,
      default: 0
    },
  },
  data: () => ({
    baseUrl: process.env.API_URL
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
