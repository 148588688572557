<template>
  <div :class="`col-12 col-md-${size}`" style="user-select: none">
    <div
      :class="['border-radius border-solid bg-white q-pa-md text-grey-4', size === 12 ? '' : 'q-mx-xs', isMobile ? '' : shadow ? '' : hover ? 'border-solid hover-primary' : 'border-solid']">
      <div class="column q-gutter-y-lg">

        <!-- charity logo, category icon, title, category -->
        <div class="row full-width justify-between items-start">

          <div :class="['row', loading ? 'col q-pr-md items-center' : 'items-start']">

            <!-- charity logo, category icon -->
            <div v-if="!loading" class="row col-auto items-end">

              <!-- charity logo -->
              <q-img class="border-solid text-grey-4" style="border-radius: 50%" :src="logo" width="70px"
                     height="70px">
                <template v-slot:loading>
                  <q-spinner-tail
                    color="grey-4"
                    size="md"
                  />
                </template>
              </q-img>

              <!-- category icon -->
              <q-icon class="bg-white border-solid q-pa-xs" style="position: relative; left: 20px; border-radius: 50%"
                      :name="icon" size="sm" color="primary"/>

            </div>

            <!-- skeleton -->
            <div v-else class="row col-auto q-mr-md">
              <q-skeleton type="circle" width="70px" height="70px"/>
            </div>

            <!-- title, category -->
            <div v-if="!loading" class="column col q-mt-md">

              <!-- title -->
              <div :class="['text-grey-9 q-mb-xs line-clamp-1', isMobile ? 'text-subtitle2' : 'text-subtitle2']">
                {{ title }}

                <!--                <q-tooltip v-if="title && title.length > 25" class="bg-black text-caption">
                                  {{ title }}
                                </q-tooltip>-->

              </div>

              <!-- category -->
              <div class="text-primary">
                {{ category }}
              </div>

            </div>

            <!-- skeleton -->
            <div v-else class="column col">

              <!-- title -->
              <q-skeleton class="q-mb-xs" type="text"/>

              <!-- category -->
              <q-skeleton type="text"/>

            </div>

          </div>

          <!-- make campaign favorite -->
          <div v-if="$route.name === 'projectInfo'" class="col-auto">

            <!-- button -->
            <q-btn v-if="!loading" class="hover-primary" size="14px"
                   :color="liked ? 'primary' : 'grey-6'" :icon="liked ? 'la la-heart' : 'lar la-heart'" flat round
                   dense @click="$emit('toggleFavorite')">
              <q-tooltip v-if="!isMobile" class="border-radius bg-black text-caption" transition-show="jump-right"
                         transition-hide="jump-left" anchor="center start" self="center end">
                {{ liked ? 'حذف از پویش‌های محبوب' : 'افزودن به پویش‌های محبوب' }}
              </q-tooltip>
            </q-btn>

            <!-- skeleton -->
            <q-skeleton v-else type="circle" size="30px"/>

          </div>

        </div>

        <!-- description -->
        <div v-if="body && showDescription"
             :class="['row full-width text-grey-9 text-justify overflow-hidden line-clamp-2', loading ? 'hidden' : undefined]"
             style="line-height: 24px; height: 48px">
          {{ description.htmlToText() }}
        </div>

        <!-- skeleton -->
        <div v-if="$route.name !== 'projectInfo' && loading" class="column full-width">

          <q-skeleton class="full-width q-mb-xs" type="text"/>

          <q-skeleton class="full-width" type="text"/>

        </div>

        <!-- funding progress -->
        <div v-if="body && !loading" class="column full-width">

          <!-- progress bar -->
          <q-linear-progress :value="progress === null ? (involvementRate / 100) : (progress / 100)" :color="getColor"
                             size="lg" stripe rounded/>

          <!-- info -->
          <div class="row full-width q-mt-sm justify-between items-center">

            <!-- money raised, money required -->
            <div class="row items-center text-caption">

              <!-- money raised -->
              <div class="text-bold text-grey-9">
                {{ moneyRaised && moneyRaised === 0 ? 0 : (moneyRaised/ 10).toMoney('تومان') }}
              </div>

              <div class="text-grey-6 q-mx-sm">
                {{ moneyRequired > 0 ? 'از' : 'جمع‌آوری‌شده' }}
              </div>

              <!-- money required -->
              <div class="text-bold text-grey-9">
                {{ moneyRequired > 0 ? (moneyRequired / 10).toMoney('تومان') : undefined }}
              </div>

            </div>

            <!-- progress percent -->
            <div v-if="progress !== null" :class="[`text-subtitle2 text-bold text-${getColor}`]">
              {{ progress >= 100 ? '100%' : progress + '%' }}
            </div>

            <div v-else :class="[`text-subtitle2 text-weight-regular text-${getColor}`]">
              {{ 'میزان مشارکت' }}
            </div>

          </div>

        </div>

        <!-- skeleton -->
        <div v-else class="row full-width">
          <q-skeleton class="full-width" type="rect"/>
        </div>

        <!-- days left, number of people involved, support campaign -->
        <div v-if="body && !loading" class="row full-width justify-between items-center">

          <!-- days left, number of people involved -->
          <div class="row q-gutter-x-lg items-center">

            <!-- days left -->
            <div v-if="daysLeft && limited" class="row items-center">

              <!-- icon -->
              <q-icon name="la la-stopwatch" color="grey-8" size="sm">
                <q-tooltip v-if="size <= 6 || isMobile" class="bg-black text-caption">
                  روزهای باقی مانده
                </q-tooltip>
              </q-icon>

              <!-- number -->
              <div v-if="daysLeft > 0" class="text-subtitle2 text-grey-8 q-mx-sm q-pt-xs">
                {{ daysLeft }}
              </div>

              <!-- label -->
              <div v-if="daysLeft > 0" class="text-weight-regular text-grey-9">
                {{ (size < 6) || isMobile ? 'روز' : 'روز باقی مانده' }}
              </div>

              <div v-else class="text-weight-regular text-grey-9 q-ml-xs">
                تمام شد
              </div>

            </div>

            <!-- number of people involved -->
            <div v-if="involvedPeopleCount" class="row items-end">

              <!-- icon -->
              <q-icon name="la la-user" color="grey-8" size="sm">
                <q-tooltip v-if="size <= 6 || isMobile" class="bg-black text-caption">
                  شرکت کنندگان
                </q-tooltip>
              </q-icon>

              <!-- number -->
              <div class="text-caption text-weight-regular text-grey-9 q-mx-sm">
                {{ involvedPeopleCount }}
              </div>

              <!-- label -->
              <div v-if="size >= 6 && !isMobile" class="text-weight-regular text-grey-9">
                شرکت کننده
              </div>

            </div>

          </div>

          <!-- support campaign -->
          <router-link v-if="$route.name !== 'projectInfo'" style="text-decoration: none" tag="div" :to="link">
            <q-btn
              :class="['border-radius text-weight-regular q-px-md', $route.name === 'projectInfo' ? (status === 1 ? 'hidden' : '') : undefined]"
              :color="status === 1 ? 'green-13' : 'cyan'"
              :label="status === 1 ? 'مشارکت' : 'مشاهده'" unelevated dense/>
          </router-link>

          <q-chip v-else class="border-radius text-subtitle2 q-px-sm q-py-xs"
                  :color="status === 1 ? 'green-13' : 'cyan'"
                  :icon-right="status === 1 ? 'la la-spinner' : 'la la-ban'"
                  :label="status === 1 ? 'جاری' : 'پایان یافته'" :ripple="false" outline/>

        </div>

        <!-- skeleton -->
        <div v-else class="row full-width justify-between items-center">

          <!-- days left, number of people involved -->
          <div class="row q-gutter-x-lg items-center">

            <!-- days left -->
            <q-skeleton type="QBadge"/>

            <!-- number of people involved -->
            <q-skeleton v-if="limited" type="QBadge"/>

          </div>

          <!-- support campaign -->
          <q-skeleton type="rectangle" width="100px" height="26px"/>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignItem",
  props: {
    size: {
      type: Number,
      default: 3
    },
    body: {
      type: Boolean,
      default: true
    },
    shadow: {
      type: Boolean,
      default: true
    },
    hover: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    title: String,
    category: String,
    description: String,
    showDescription: {
      type: Boolean,
      default: true
    },
    status: Number,
    moneyRaised: Number,
    moneyRequired: Number,
    progress: Number,
    involvementRate: Number,
    daysLeft: Number,
    involvedPeopleCount: Number,
    link: {
      type: String,
      default: ''
    },
    liked: {
      type: Boolean,
      default: false
    },
    limited: Boolean,
    loading: Boolean
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
    getColor() {
      let percent = this.progress ? this.progress : this.involvementRate
      if (percent === 0)
        return 'grey'
      else if (percent > 0 && percent < 25)
        return 'red-13'
      else if (percent >= 25 && percent < 50)
        return 'orange'
      else if (percent >= 50 && percent < 75)
        return 'cyan'
      else return 'green-13'
    }
  },
}
</script>
