<template>
  <router-link
    :class="['column border-radius border-solid bg-white q-pa-md text-grey-4', clickable ? undefined : 'cursor-inherit']"
    style="text-decoration: none" tag="div" :to="clickable ? `/challenge/${id}` : '#'">
    <div class="column full-width q-gutter-y-md">

      <!-- charity logo, title, charity, add to favorites -->
      <div class="row full-width justify-between items-start">

        <!-- charity logo, title, charity -->
        <div class="row col q-pr-md items-stretch">

          <!-- charity logo -->
          <q-img :src="charityLogo && `${baseUrl}/cdn/download/${charityLogo.fileName}`" width="50px" height="50px"/>

          <!-- title, charity -->
          <div class="column col full-height q-pl-md justify-around">

            <!-- title -->
            <h4 class="q-mx-none q-mt-none q-mb-sm text-caption  text-weight-regular text-black line-clamp-1">
              {{ title }}
            </h4>

            <!-- charity -->
            <div :class="`text-subtitle2 text-weight-regular text-secondary`">
              {{ charityTitle }}
            </div>

          </div>

        </div>

        <!-- add to favorites -->
        <div v-if="$store.state.user && favorite" class="col-auto">
          <q-btn class="hover-primary" size="14px"
                 :color="isFavorite ? 'primary' : 'grey-6'" :icon="isFavorite ? 'la la-heart' : 'lar la-heart'" flat
                 round
                 dense @click="$emit('toggleFavorite')">
            <q-tooltip v-if="!isMobile" class="border-radius bg-black text-caption" transition-show="jump-right"
                       transition-hide="jump-left" anchor="center start" self="center end">
              {{ isFavorite ? 'حذف از پویش‌های محبوب' : 'افزودن به پویش‌های محبوب' }}
            </q-tooltip>
          </q-btn>
        </div>

      </div>

      <!-- description -->
      <div v-if="showDescription && description" class="row full-width">
        <div class="text-subtitle2 text-weight-light text-justify text-grey-8 line-clamp-2"
             style="line-height: 24px">
          {{ description && description.htmlToText() }}
        </div>
      </div>

      <!-- progress, collected amount, estimated cost -->
      <div class="column full-width">

        <!-- progress -->
        <q-linear-progress class="q-mb-sm" style="border-radius: 4px"
                           :value="limited ? (progress > 100 ? 1 : progress / 100) : (involvementRate / 100)" size="lg"
                           :color="progressColor" stripe/>

        <!-- collected amount, estimated cost -->
        <div class="row full-width justify-between">

          <!-- collected amount, estimated cost -->
          <div class="row q-gutter-x-xs items-center">

            <!-- collected amount -->
            <div
              :class="['text-weight-regular', status === 1 ? 'text-subtitle2 text-grey-8' : 'text-subtitle2 text-cyan']">
              {{
                moneyRaised && $route.name === 'projectInfo' ? (moneyRaised === 0 || moneyRaised == null ? 0 : (moneyRaised / 10).toNumber()) : moneyRaised === 0 || moneyRaised == null ? 0 : moneyRaised?.numToTextSimple()
              }}
            </div>

            <div class="text-subtitle2 text-weight-light text-grey-7">
              {{ !limited || (limited && status === 2) ? 'جمع‌آوری‌شده' : 'از' }}
            </div>

            <!-- estimated cost -->
            <div v-if="limited && status === 1" class="text-subtitle2 text-weight-regular text-grey-8">
              {{
                estimatedCost && $route.name === 'projectInfo' ? (estimatedCost / 10).toNumber() + ' تومان' : estimatedCost.numToTextSimple()
              }}
            </div>

          </div>

          <!-- percent -->
          <div :class="`text-caption text-bold text-${progressColor}`">
            {{
              limited ?
                (progress > 100 ? '100' : progress === 0 ? '0' : progress) :
                (involvementRate > 100 ? '100' : involvementRate === 0 ? '0' : involvementRate)
            }}%
          </div>

        </div>

      </div>

      <!-- remaining days, donors count, project status -->
      <div v-if="extraDetails" class="row full-width justify-between items-end">

        <!-- remaining days -->
        <div v-if="limited && remainingDays > 0" class="column items-center">

          <!-- title -->
          <div class="q-mb-xs text-caption text-weight-regular text-grey-8">
            ‌باقی‌مانده
          </div>

          <!-- number -->
          <div class="text-subtitle2 text-black">
            {{ remainingDays + ' روز' }}
          </div>

        </div>

        <!-- donors count -->
        <div class="column items-center">

          <!-- title -->
          <div class="q-mb-xs text-caption text-weight-regular text-grey-8">
            مشارکت
          </div>

          <!-- number -->
          <div class="text-subtitle2 text-black">
            {{ sponsorCount > 0 ? (sponsorCount + ' حامی') : 0 }}
          </div>

        </div>

        <!-- project status -->
        <div class="column items-center">

          <!-- title -->
          <div class="q-mb-xs text-caption text-weight-regular text-grey-8">
            وضعیت
          </div>

          <!-- status -->
          <div :class="['text-subtitle2', status === 1 ? 'text-green-13' : 'text-cyan']">
            {{ status === 1 ? 'جاری' : 'پایان یافته' }}
          </div>

        </div>

      </div>

    </div>
  </router-link>
</template>

<script>
export default {
  name: "CampaignItemMobile",
  props: {
    clickable: {
      type: Boolean,
      default: true
    },
    extraDetails: {
      type: Boolean,
      default: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    favorite: {
      type: Boolean,
      default: false
    },
    id: Number,
    limited: Boolean,
    charityLogo: Object,
    title: String,
    charityTitle: String,
    isFavorite: {
      type: Boolean,
      default: false
    },
    description: String,
    progress: Number,
    involvementRate: Number,
    moneyRaised: Number,
    estimatedCost: Number,
    remainingDays: Number,
    sponsorCount: Number,
    status: Number
  },
  data: () => ({
    baseUrl: process.env.API_URL,
    colors: ['pink', 'cyan', 'deep-purple', 'teal', 'blue', 'green', 'light-green', 'orange']
  }),
  computed: {
    progressColor() {
      let percent = this.limited ? this.progress : this.involvementRate
      if (percent === 0)
        return 'grey'
      else if (percent > 0 && percent < 25)
        return 'red-13'
      else if (percent >= 25 && percent < 50)
        return 'orange'
      else if (percent >= 50 && percent < 75)
        return 'cyan'
      else return 'green-13'
    },
    isMobile() {
      return this.$q.screen.lt.md
    }
  }
}
</script>

<style scoped>
/*h4 {*/
/*  font-size: 14px !important;*/
/*  line-height: 18px;*/
/*}*/
</style>
