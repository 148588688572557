<template>
  <router-link v-if="isGridShow || isMobile"
               class="col-12 col-md-3 q-mb-md"
               style="text-decoration: none"
               tag="div"
               :to="`/blog/${post.id}`" >
    <div class="full-width border-radius border-solid post overflow-hidden text-grey-4">
      <div class="column full-width">

        <!-- image -->
        <q-img :src="post.image ? `${baseUrl}/cdn/download/${post.image.fileName}` : 'images/noPic.png'"
               width="100%" height="230px" :ratio="4/3" fit="cover">
          <template v-slot:loading>
            <q-spinner-tail color="black" size="xs"/>
          </template>
        </q-img>

        <!-- body -->
        <div class="column full-width q-pa-md q-gutter-y-md">

          <!-- title -->
          <div class="row full-width items-center">
            <div class="text-grey-9 text-subtitle1 text-bold line-clamp-2" style="height: 50px !important;">
              {{ post.title }}
            </div>
          </div>

          <!-- description -->
          <div v-if="showDescription" class="row full-width items-center">
            <div class="line-clamp-2 line-height text-caption text-justify text-black">
              {{ post.metaDescription }}
            </div>
          </div>

          <!-- view count, publish date -->
          <div class="row full-width justify-between items-center">

            <!-- view count -->
            <div class="text-caption text-grey-8">
              {{ post.viewCount + ' بازدید' }}
            </div>

            <!-- publish date -->
            <div class="text-caption text-grey-7">
              {{ post.publishDate && post.publishDate.getElapsedTime() }}
            </div>

          </div>

        </div>

      </div>
    </div>
  </router-link>

  <router-link v-else class="full-width" style="text-decoration: none" tag="div" :to="`/blog/${post.id}`">
    <div class="row full-width border-radius border-solid overflow-hidden text-grey-4">

      <!-- image -->
      <div class="col-12 col-md-3 border-right">
        <q-img :src="post.image ? `${baseUrl}/cdn/download/${post.image.fileName}` : 'images/noPic.png'"
               width="100%" height="140px" :ratio="4/3" fit="cover">
          <template v-slot:loading>
            <q-spinner-tail color="black" size="xs"/>
          </template>
        </q-img>
      </div>

      <!-- body -->
      <div class="col-9 bg-white">
        <div class="column full-width q-pa-md q-gutter-y-sm">

          <!-- title -->
          <div class="row full-width items-center">
            <div class="line-clamp-2 text-grey-9 text-subtitle1 text-bold">
              {{ post.title }}
            </div>
          </div>

          <!-- description -->
          <div v-if="showDescription" class="row full-width items-center">
            <div class="line-clamp-2 text-justify text-grey-10 text-subtitle2 text-weight-regular">
              {{ post.metaDescription }}
            </div>
          </div>

          <!-- view count, publish date -->
          <div class="row full-width justify-between items-center">

            <!-- view count -->
            <div class="text-caption text-grey-8">
              {{ post.viewCount + ' بازدید' }}
            </div>

            <!-- publish date -->
            <div class="text-caption text-grey-8">
              {{ post.publishDate && post.publishDate.getElapsedTime() }}
            </div>

          </div>

        </div>
      </div>

    </div>
  </router-link>

</template>

<script>
export default {
  name: "BlogPost",
  props: {
    post: Object,
    showDescription:Boolean,
    isGridShow:Boolean
  },
  data: () => ({
    baseUrl: process.env.API_URL
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
  }
}
</script>

<style lang="scss" scoped>
.line-height {
  line-height: 40px;
}

.post {
  background-color: white;
  transition: 0.4s;
}

.post:hover {
  background-color: $grey-3;
  transition: 0.4s;
}
</style>
