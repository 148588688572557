<template>
  <div class="full-width border-radius border-solid bg-white text-grey-4">
    <div class="column full-width q-pa-md" style="text-align: right !important;">

      <!-- title -->
      <div class="row full-width border-radius bg-blue-grey-1 justify-center q-mb-md q-pa-sm">
        <div class="line-clamp-1 text-subtitle1 text-bold text-black">
          {{ report.title }}
        </div>
      </div>

      <!-- charity -->
      <q-no-ssr class="row full-width q-mb-sm">
        <div class="line-clamp-1 text-subtitle2 text-secondary cursor-pointer"
             @click="setFilter('tenantId', report.tenantId, report.charityTitle)">
          #{{ report && report.charityTitle && report.charityTitle.trim().replaceAll(' ', '_') }}
        </div>
      </q-no-ssr>

      <!-- project -->
      <q-no-ssr v-if="report.projectId" class="row full-width q-mb-md">
        <div class="line-clamp-1 text-subtitle2 text-secondary cursor-pointer"
             @click="setFilter('projectId', report.projectId, report.projectTitle)">
          #{{ report && report.projectTitle && report.projectTitle.trim().replaceAll(' ', '_') }}
        </div>
      </q-no-ssr>

      <!-- donation -->
      <q-no-ssr v-else-if="report.donationId" class="row full-width q-mb-md">
        <div class="line-clamp-1 text-subtitle2 text-secondary cursor-pointer"
             @click="setFilter('donationId', report.donationId, report.donationTitle)">
          #{{ report && report.donationTitle && report.donationTitle.trim().replaceAll(' ', '_') }}
        </div>
      </q-no-ssr>

      <!-- description -->
      <div class="row full-width q-mb-md">
        <div class="ck-content line-clamp-2 line-height text-grey-9" v-html="report.description.htmlToText()"/>
      </div>

      <!-- date, view report -->
      <div class="row full-width justify-between items-end">

        <!-- date -->
        <div class="text-caption text-grey-8" @mouseover="showDate = true" @mouseleave="showDate = false">
          {{ showDate ? report.date.toJalaliFullDate() : report.date.getElapsedTime() }}
        </div>

        <!-- view report -->
        <q-btn class="border-radius q-px-md" color="secondary" label="مشاهده" outline dense
               :to="`/report/${report.id}`" target="_blank"/>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ReportItem",
  props: {
    report: Object
  },
  data: () => ({
    showDate: false
  }),
  methods: {
    setFilter(fieldName, value, title) {
      this.$emit('setFilter', {fieldName, value, title})
    }
  },
}
</script>

<style scoped>
.line-height {
  line-height: 28px;
}
</style>
