<template>
  <div :class="`col-12 col-md-${size}`">
    <div
      :class="['border-radius border-solid bg-white q-pa-md text-grey-4', vertical ? isMobile ? '' : shadow ? 'q-mx-xs' : 'q-mx-xs' : shadow ? 'q-mx-sm' : 'q-mx-sm']">
      <div class="row full-width q-gutter-y-md justify-between items-stretch">

        <!-- overall info -->
        <div :class="['items-center', vertical ? 'row col-12 justify-around' : 'column col-3 justify-center']">

          <!-- logo -->
          <q-img v-if="!loading" :class="['border-solid text-grey-4', vertical ? '' : 'q-mb-sm']"
                 style="border-radius: 50%" :src="logo"
                 width="110px"
                 height="110px">
            <template v-slot:loading>
              <q-spinner-tail
                color="grey-4"
                size="md"
              />
            </template>
          </q-img>

          <!-- skeleton -->
          <q-skeleton v-else class="q-mb-sm" type="circle" width="110px" height="110px"/>

          <div class="column items-center">

            <!-- title -->
            <div v-if="!loading"
                 :class="['text-bold text-grey-9 q-mb-sm', isMobile ? 'text-caption' : 'text-subtitle1']">
              {{ title }}
            </div>

            <!-- skeleton -->
            <q-skeleton v-else class="q-mb-sm" type="QBadge"/>

            <!-- foundation date -->
<!--            <div v-if="!loading" :class="['row text-grey-7', vertical ? 'q-my-xs' : '']">-->

<!--              تاسیس:-->

<!--              <div class="text-primary q-ml-xs">-->
<!--                {{ foundationDate }}-->
<!--              </div>-->

<!--            </div>-->

            <!-- skeleton -->
<!--            <q-skeleton v-else :class="vertical ? 'q-my-xs' : undefined" type="text" width="60%"/>-->

            <!-- type -->
<!--            <q-chip v-if="!loading" class="border-radius q-mt-sm text-weight-regular" :label="getTypeName"-->
<!--                    :color="getTypeColor"-->
<!--                    icon="la la-medal" outline :ripple="false"/>-->

            <!-- skeleton -->
<!--            <q-skeleton v-else class="border-radius q-mt-sm" type="rect" width="100%"/>-->

          </div>

        </div>

        <!-- separator -->
        <q-separator v-if="body" :class="vertical ? 'full-width' : ''" :vertical="!vertical" inset/>

        <!-- details -->
        <div v-if="body" :class="['column justify-between items-center', vertical ? 'col-12' : 'col-8']">

          <!-- description -->
          <div v-if="description" :class="['row full-width items-center', vertical ? 'q-mb-md' : '']">

            <!-- content -->
            <div v-if="!loading" class="text-body2 text-weight-regular text-grey-9 text-justify line-clamp-2"
                 :style="{'line-height': '26px', 'height': $route.name !== 'charityProfile' ? '48px' : undefined}">
              {{ description }}
            </div>

            <!-- skeleton -->
            <div v-else class="column full-width">

              <q-skeleton class="full-width" type="text"/>

              <q-skeleton class="full-width" type="text"/>

            </div>

          </div>

          <!-- details -->
          <div
            :class="['row q-gutter-y-md justify-center', vertical ? 'items-start' : 'full-width items-center', loading ? vertical ? 'full-width' : undefined : undefined]">

            <!-- registration date -->
            <div :class="['row items-center', vertical ? 'col-12 justify-start' : 'col-6 justify-start']">

              <!-- icon -->
              <q-icon v-if="!loading" name="la la-calendar" color="grey-9" size="sm"/>

              <!-- title -->
              <div v-if="!loading" class="text-grey-8 q-mx-sm">
                تاریخ عضویت :
              </div>

              <!-- number -->
              <div v-if="!loading" class="text-subtitle2 text-grey-9">
                {{ registrationDate }}
              </div>

              <!-- skeleton -->
              <q-skeleton v-else type="QBadge" width="60%"/>

            </div>

            <!-- campaigns -->
            <div :class="['row items-center', vertical ? 'col-12 justify-start' : 'col-6 justify-start']">

              <!-- icon -->
              <q-icon v-if="!loading" name="la la-donate" color="grey-9" size="sm"/>

              <!-- title -->
              <div v-if="!loading" class="text-grey-8 q-mx-sm">
                پویش‌ها :
              </div>

              <!-- number -->
              <div v-if="!loading" class="text-subtitle2 text-grey-9">
                {{ successfulCampaigns }}
              </div>

              <!-- skeleton -->
              <q-skeleton v-else type="QBadge" width="60%"/>

            </div>

            <!-- supporters -->
            <div :class="['row items-center', vertical ? 'col-12 justify-start' : 'col-6 justify-start']">

              <!-- icon -->
              <q-icon v-if="!loading" name="la la-life-ring" color="grey-9" size="sm"/>

              <!-- title -->
              <div v-if="!loading" class="text-grey-8 q-mx-sm">
                حامیان :
              </div>

              <!-- number -->
              <div v-if="!loading" class="text-subtitle2 text-grey-9">
                {{ supporters }}
              </div>

              <!-- skeleton -->
              <q-skeleton v-else type="QBadge" width="60%"/>

            </div>

            <!-- help-seekers -->
            <div :class="['row items-center', vertical ? 'col-12 justify-start' : 'col-6 justify-start']">

              <!-- icon -->
              <q-icon v-if="!loading" name="la la-user" color="grey-9" size="sm"/>

              <!-- title -->
              <div v-if="!loading" class="text-grey-8 q-mx-sm">
                مهرجویان :
              </div>

              <!-- number -->
              <div v-if="!loading" class="text-subtitle2 text-grey-9">
                {{ helpSeekers }}
              </div>

              <!-- skeleton -->
              <q-skeleton v-else type="QBadge" width="60%"/>

            </div>

          </div>

          <!-- view profile -->
          <div v-if="profile" class="row full-width justify-end items-center">

            <!-- button -->
            <router-link v-if="!loading" tag="div" :to="link" style="text-decoration: none">
              <q-btn :class="['border-radius text-weight-regular q-px-md', vertical ? 'full-width' : '']"
                     color="secondary"
                     label="مشاهده پروفایل" unelevated dense/>
            </router-link>

            <!-- skeleton -->
            <q-skeleton class="border-radius" v-else type="QBtn" width="30%"/>

          </div>

          <!-- like campaign -->
<!--          <div v-if="$route.name === 'charityProfile'" class="row full-width justify-end items-center">-->
<!--            <q-btn-->
<!--              :class="['full-width border-radius text-subtitle2', liked ? 'text-weight-light' : 'text-weight-regular']"-->
<!--              color="primary"-->
<!--              :icon-right="liked ? 'favorite' : 'favorite_border'"-->
<!--              :label="liked ? 'حذف از موسسات محبوب' : 'افزودن به موسسات محبوب'" :unelevated="liked"-->
<!--              :outline="!liked"-->
<!--              @click="liked = !liked">-->
<!--            </q-btn>-->
<!--          </div>-->

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharityItem",
  props: {
    body: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 6
    },
    shadow: {
      type: Boolean,
      default: true
    },
    vertical: Boolean,
    profile: Boolean,
    type: {
      type: Number,
      default: 1
    },
    logo: {
      type: String,
      default: undefined
    },
    title: String,
    foundationDate: String | Number,
    referenceNumber: {
      type: Number,
      default: undefined
    },
    description: String,
    registrationDate: String,
    successfulCampaigns: Number,
    supporters: Number,
    helpSeekers: Number,
    link: {
      type: String,
      default: ''
    },
    liked: {
      type: Boolean,
      default: false
    },
    loading: Boolean
  },
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
    getTypeName() {
      let type = this.type
      if (type === 1)
        return 'مرکز نیکوکاری'
      else if (type === 2)
        return 'مردم نهاد'
      else return 'گروه'
    },
    getTypeColor() {
      let type = this.type
      if (type === 1)
        return 'red-5'
      else if (type === 2)
        return 'cyan-7'
      else return 'orange-7'
    }
  }
}
</script>
